<template>
    <div>
        <el-row>
             <el-form>
                 <el-col :span="12">
                     <el-form-item label="平台手机号：" label-width="120px">
                          <el-input v-model="phone"></el-input>
                     </el-form-item>
                 </el-col>
                 <el-col :span="12">
                      <el-button type="primary" class="ml20" @click="submitPhone">修改</el-button>
                 </el-col>
             </el-form>
        </el-row>
    </div>
</template>

<script>
export default {
    data(){
      return {
         phone:'' ,
      }  
    },
    created(){
       this.getPlatForm()
    },
    methods:{
        submitPhone(){
            const data = {}
             data.propertyValue = this.phone
            this.$http.post('/systemConfig/updatePlatFormPhone',data).then(res => {
              if(res.data.code == 200) {
                  this.$notify.success({
                        title: '提示',
                        message: '修改成功',
                        showClose: true
                    })
                    this.getPlatForm()
               }
            })
        },
        getPlatForm(){
            const data = {}
            this.$http.post('/systemConfig/queryPlatFormPhone',data).then(res => {
              if(res.data.code == 200) {
                  this.phone = res.data.data
               }
            })
        }
    }

}
</script>

<style scoped>
  
</style>